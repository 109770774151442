/**
 * @author: Ashay Tiwari
 * @date: 6 Sep 2021
 * @description: English Language JSON file
 * @developedBy: Kritin Digital Solutions Pvt. Ltd.
 */
export const TRANSLATION_ENGLISH = {
  common: {
    signup: "Sign up",
    signin: "Sign in",
    logOut: "Log out",
    english: "English",
    german: "German",
    submit: "Submit",
    reset: "Reset",
    back: "Back",
    skip: "Skip",
    next: "Next",
    upload: "Upload",
    wantToRegisterAsCompany: "Want to register as Company?",
    alreadyHaveAnAccount: "Already have an account?",
    wantToRegisterAsStudent: "Want to register as Student?",
    student: "Student",
    company: "Company",
    benefitWorld: "Benefit World",
    eventWorld: "Event World",
    searchOnHipoz: "Search on hipoZ",
    yourFavorites: "Your Favorites",
    all: "All",
    youMayLike: "You may like",
    jobsMayLike: "Jobs you may like",
    studentsMayLike: "Students may like",
    yourEvents: "Your Events",
    seeAll: "See all",
    about: "About",
    jobs: "Jobs",
    jobsApplied: "Job's you have applied for",
    filters: "Filters",
    clearFilters: "Clear Filters",
    yourCandidateList: "Your Candidate List",
    postJobs: "Post a new job",
    subscriptionPlan: "Subscription Plan",
    website: "Company Website",
    post: "Post",
    product: "Product",
    event: "Event",
    address: "Address",
    articles: "Articles",
    relevant: "Relevant",
    ourEvents: "Our Events",
    ourProducts: "Our Products",
    newest: "Newest",
    messages: "Messages",
    typeYourMessage: "Type your message",
    noCompany: "No Favourite Company available",
    noResult: "No results found for your search",
    noMember: "You are not a Hipoz community member, Please contact admin for upgrading your profile",
    noJobs: "No matching jobs available yet",
    noAppliedJobs: "No jobs applied yet!",
    myProfile: "My Profile",
    male: "Male",
    female: "Female",
    diversed: "Diversed",
    immediate: "Immediately",
    twoWeeks: "2 Weeks",
    oneMonth: "1 Month",
    twoMonths: "2 Months",
    threeMonths: "3+ Months",
    intern: "Intern",
    fulltime: "Fulltime",
    partTime: "Part time",
    jan: "January",
    feb: "February",
    march: "March",
    april: "April",
    may: "May",
    june: "June",
    july: "July",
    aug: "August",
    sept: "September",
    oct: "October",
    nov: "November",
    dec: "December",
    basic: "Basic",
    intermediate: "Intermediate",
    advance: "Advanced",
    expert: "Expert",
    native: "Native Speaker",
    readyToWork: "Ready to work",
    terms: "Terms",
    jobApply: "Apply now",
    applyTitle: "I am applying for this job because ...",
    stayTuned: "Stay tuned! All current jobs will be published here at our kick off!",
    stayTunedStudent: "Stay tuned! All companies will be published here at our kick off!",
    stayTunedCompany: "Stay tuned! All students will be published here at our kick off!"
  },
  messaging: {
    noChat: "No chat History avaiable"
  },
  homePage: {
    banner: {
      hipoz: "hipoZ",
      line1: "is the first digital platform for",
      line2: "high potentials and young professionals!",
      line3: "Be a part of something big and sign up now!",
      highQuality: "High Quality",
      team: "Team",
      members: "Members",
      description:
        "hipoz is a digital zone for top students, graduates and young professionals. Get access to exclusive benefits, invitations to hi-class events and top lectures.",
      scrollMore: "Scroll for more"
    },
    benefits: {
      applyFor: "Apply for",
      membership: "membership!",
      line1: "hipoZ is a platform that enables high potentials.",
      line2: "hipoZ aims to network, promote and inform.",
      line3: "But hipoZ also means fun, party plus benefits.",
      line4: "hipoZ is your springboard for a top career.",
      line5: "Make the move and reach your full potential!",
      description1:
        "hipoZ is a platform that enables high potentials. hipoZ aims to network, promote and inform. But hipoZ also means fun, party plus benefits. hipoZ is your springboard for a top career. Make the move and reach your full potential! ",
      getHipoz: "About us!",
      package: "Starter Package",
      description2:
        "Hipoz is a digital zone for top students, graduates and young professionals. Get exclusive benefits, invitations to hi-class events and lectures."
    },
    contact: {
      contactUs: "Contact Us",
      student: "I'm a Student",
      company: "I'm a company",
      emailAddress: "Email Address",
      message: "Message",
      austria: "Austria / Vienna",
      hipoz: "hipoZ",
      gmbh: "GmbH"
    }
  },
  login: {
    nameEmailAddress: "Name or email address",
    yourNameOrEmailAddress: "Your name or email address*",
    password: "Password",
    yourPassword: "Your Password",
    forgot: "Forgot Password",
    dontHaveAccount: "Don't have an account"
  },
  messageConstants: {
    networkError: "Network Error",
    tryAgainLaterError: "Try again later",
    allFieldMandatory: "All fields are mandatory",
    validEmailAddress: "Please enter a valid email address",
    validPhoneNumerAddress: "Please enter a valid mobile number",
    salaryFromShouldBeSmallerThanSalaryTo: "Salary From should be smaller than Salary To",
    salaryShouldBeGreaterThanZero: "Salary should be greater than zero",
    expressInterestFormMandatory: "Fill the form before submission",
    firstEnterSalaryFrom: "Firstly enter salary from",
    mobileNoMustContainNumbers: "Mobile Number should contain Numeric Digits",
    emailIsRequired: "Email is required",
    passwordMismatch: "Your password and confirmation password do not match",
    errorInResetingPass: "Error in reseting password",
    errorInGettingEmail: "Error in getting your email",
    passwordLengthShouldBe8: "Your password must contain atleast 8 characters",
    tokenIsNotAccesible: "Token is not accessible, try again later",
    errorInSubmittingDetails: "Error in submitting details",
    firstlyFillTheseSet: "Please fill these set of details before proceeding",
    fillLastSet: "Please fill last set of details or remove it",
    adminMandatoryField: "Email and Password is required",
    mandatoryDescription: "Please fill the description",
    mandatoryRoleExpectation: "Please fill role expectation",
    mandatoryInterestedWork: "Please fill interested work",
    mandatoryIndustryField: "Please fill industry field",
    mandatryCountryLocation: "Please fill your prefered countries",
    mandatoryJobType: "Please fill your job preferences"
  },
  forgotPassword: {
    forgot: "Forgot Password",
    emailLabel:
      "Enter the email address associated with your account to reset password",
    email: "Email*",
    return: "Return to"
  },
  resetPassword: {
    password: "Password",
    confirmPassword: "Confirm Password",
    resetPassword: "Reset Password",
    returnLogin: "Return to Login"
  },
  setPassword: {
    registeredEmail: "Registered Email*",
    password: "Password",
    returnLogin: "Return to Login",
    setPassword: "Set Password"
  },
  studentSignup: {
    page1: {
      firstName: "First Name",
      yourFirstName: "Your first name",
      lastName: "Last Name",
      yourLastName: "Your Last Name",
      emailAddress: "Email Address",
      yourEmailAddress: "Your email address",
      phNo: "Phone Number",
      yourPhNo: "Your Phone Number",
      password: "Password",
      yourPassword: "Your Password",
      reenterPassword: "Re-enter Password",
      termsAndCond: "Agreed to terms and conditions",
      optional: "Ich willige ein, dass die Profil Redaktion GmbH & Co KG, Leopold-Ungar-Platz 1, A-1190 Wien, meine personenbezogenen Daten ((Name, E-Mail-Adresse) erhält und für die Übermittlung passender Aktionen (Abo-Angebote, Ankündigungen, Newsletter und Gewinnspiele) verwendet."
    },
    page2: {
      photoProfile: "Profile picture",
      gender: "Gender",
      dateOfBirth: "Date of Birth",
      country: "Country",
      postCode: "Post Code",
      regional: "Regional",
      city: "City",
      homeAddress: "Home Address",
      yourGender: "Your Gender",
      yourPostCode: "Your Post Code",
      yourRegion: "Your Region",
      yourCity: "Your City",
      yourHomeAddress: "Your Home Address"
    },
    page3: {
      degree: "Degree",
      yourDegree: "Your Degree",
      fieldOfStudy: "Field of Study",
      yourField: "Your Field of Study",
      university: "University",
      yourUniversity: "Your University",
      graduatedOn: "I have graduated on",
      courseStarted: "Start date",
      currentlyStudying: "Currently Studying",
      currentlyWorking: "I worked during",
      month: "Month",
      year: "Year",
      addAnother: "Add another",
      addAnotherGraduated: "Add another graduated history",
      addAnotherLang: "Add another",
      addAnotherLangPlaceHolder: "Add another graduated history",
      receivedScholarship:
        "In the course of studies, I received a merit scholarship",
      scholarshipType: "Scholarship type",
      yourScholarshipType: "Your scholarship type",
      scholarshipName: "Your Scholarship Name",
      yourScholarshipName: "Your Scholarship Name",
      workAvailability: "Work availability"
    },
    page4: {
      cv: "Curriculum vitae",
      jobType: "Job type",
      yourJobType: "Your Job type",
      languages: "Language",
      yourLanguage: "Your language",
      level: "Level",
      yourLanguageLevel: "Your language level",
      specialization: "Specialization",
      yourSpecialization: "Your Specialization",
      interestedWork: "Interested to work in",
      yourInterestedWork: "Your Interested Work",
      industryField: "Industry field",
      yourIndustryField: "Your Industry field",
      companyLocation: "Preferred Company location",
      yourCOmpanyLocation: "Your preferred Company Location",
      willingToWorkAbroad: "I would be willing to work abroad",
      salaryExpectation: "Salary expectation per month",
      yourSalary: "Your Salary Expectation",
      youExpertiseLevel: "Your skills proficiency"
    },
    page5: {
      workExp: "Work Experience",
      jobType: "Job Type",
      yourJobType: "Your Job Type",
      companyName: "Company Name",
      yourCompanyName: "Your Company Name",
      startWorking: "I have a work on",
      finishWorking: "I have finished work on",
      month: "Month",
      year: "Year",
      yes: "Yes",
      removeSet: "Remove this set",
      no: "No",
      addAnotherWork: "Add Another Work Experience",
      studyAbroadExp: "I have studied abroad",
      studyAbroadName: "Name of institution",
      yourStudiedAbroadName: "Your Name of institution",
      university: "University",
      yourUniversity: "Your University Name",
      startAbroad: "I have studied abroad during",
      finishAbroad: "I completed my studies abroad in",
      addAnotherStudyAbroad: "Add another studied abroad experience",
      certificate: "Certificates (optional)",
      uploadCertificates: "Upload your certificates"
    },
    successScreen: {
      greet: "Thank You!",
      message:
        "You have successfully submitted basic details to start! We’ll review your submission and will get back to you very soon."
    }
  },
  companySignup: {
    page1: {
      firstName: "First Name",
      yourFirstName: "Your First Name",
      lastName: "Last Name",
      yourLastName: "Your Last Name",
      gender: "Gender",
      yourGender: "Your Gender",
      companyName: "Company Name",
      yourCompanyName: "Your Company Name",
      position: "Position",
      yourPosition: "Your Position",
      emailAddress: "Company email address",
      yourEmailAddress: "Your Company Email Address",
      phNo: "Company phone number",
      yourPhNo: "Your phone number",
      password: "Password",
      yourPassword: "Your Password",
      reenterPassword: "Re-enter password",
      termsAndCond: "Agreed to Terms and Conditions"
    },
    page2: {
      logo: "Company logo",
      desc: "Description",
      yourDesc: "Your company description",
      philosophy: "Company philosophy",
      yourPhilosophy: "Your company philosophy",
      activity: "Company Activity",
      yourActivity: "Your company activity",
      culture: "Company culture",
      yourCulture: "Your company culture",
      country: "Country",
      regional: "Regional",
      yourRegional: "Your Regional",
      city: "City",
      yourCity: "Your City",
      postcode: "Post code",
      yourPostCode: "Your Post code"
    },
    page3: {
      numberOfEmployees: "Number of Employees",
      yourNoOfEmployees: "Your Number of Employees",
      specialization: "Specialization",
      yourSpecialization: "Your Specialization",
      industryField: "Industry field",
      yourIndustryField: "Your Industry field",
      branche: "Branche",
      yourBranche: "Your Branche",
      branches: "Branches",
      yourBranches: "Your Branches",
      country: "Country",
      city: "City",
      hiring: "Hiring"
    },
    page4: {
      lookigForHipozWith: "Looking for hipoZ with",
      bachelorDegree: "Bachelor and Master Degree",
      language: "Language",
      level: "Level",
      yourLangauge: "Your Language",
      yourLevel: "Your Language Level",
      jobOffers: "Job offers",
      yourJobOffers: "Your Job Offers",
      lookingForStaffIn: "Looking for staff in ",
      staff: "Your staff",
      fieldOfStudy: "Specific field of study",
      yourStudies: "Your studies",
      university: "Specific university",
      additionalQualifications:
        "Additional qualifications are advantage for us",
      yourAdvantages: "Your Advantages",
      candidateWith: "Candidate with training experience",
      trainingExp: "Training experience",
      specialization: "Specialization",
      yourSpecialization: "Your Specialization",
      uploadCertificate: "Upload your Certificate",
      otherUniversity: "Other University"
    },
    page5: {
      choosePackage: "Choose a package",
      monthly: "Monthly",
      yearly: "Yearly",
      select: "Select",
      selected: "Selected"
    },
    signupSuccessScreen: {
      greet: "Thank You!",
      message:
        "Your company has successfully finished basic details to start! You can now go to your profile and make your profile complete."
    }
  },
  studentProfile: {
    description: "Description",
    editDescription: "Edit Description",
    roleExpectation: "Role Expectation",
    editRole: "Edit Role Expectation",
    education: "Education",
    editEducation: "Edit Education",
    scholarship: "Scholarship",
    editScholar: "Edit Scholarship",
    addScholarship: "Add Scholarship",
    jobType: "Preferred Job",
    editJobType: "Edit preferred job",
    language: "Language",
    editLanguage: "Edit Language",
    addLanguage: "Add another Language",
    interestedWork: "Interested",
    editInterested: "Edit Interested",
    skills: "Skills",
    editSkills: "Edit Skills",
    addSkills: "Add another skills",
    industryField: "Industry Field",
    editIndustry: "Edit Industry Field",
    companyLocation: "Preferred Company Location",
    editCompanyLocation: "Edit Preferred Company Location",
    workExp: "Experience",
    editWorkExp: "Experience",
    salaryExpectation: "Expected salary per month",
    editSalaryExpectation: "Edit Salary Expectation",
    addAnotherSalary: "Add another salary expectation",
    resume: "Resume",
    editResume: "Edit Resume",
    profilePhoto: "Profile Photo"
  },
  sectionMessages: {
    noDescription: "No description added yet!",
    noEducation: "No education added yet!",
    noRoleExpectation: "No role expectations added yet!",
    noScholarship: "No scholarship added yet!",
    noJobType: "No job type added yet!",
    noLanguage: "No Language added yet!",
    noInterested: "No Interested work added yet!",
    noSkills: "No Skills added yet!",
    noIndustryField: "No Industry Field added yet!",
    noCompanyLocation: "No company location added yet!",
    noWorkExp: "No Work Experience added yet!",
    noSalary: "No salary expectation added yet!",
    noResume: "No resume uploaded yet!",
    noCandidates: "No candidates available yet!",
    noCompanyProduct: "No company product added yet!",
    noCompanyCulture: "No company culture added yet!",
    noCompanyPhilosophy: "No company philosophy added yet!",
    noCompanyResponsibility: "No Corporate responsibility added yet!",
    noCompanyLooking: "No looking for added yet!",
    noCompanyBranches: "No company branches added yet!",
    noLookingForStaff: "No looking for staff added yet",
    noWorkAbroad: " No work abroad added yet",
    noSubscriptionPlan:
      "No subscription plan taken yet. Get the subscription plan for more advantage"
  },
  searchEngine: {
    filters: "Fiters",
    clearFilters: "Clear Filters",
    jobField: "Job Field",
    salary: "Salary"
  },
  companyProfile: {
    product: "Company Product",
    editProduct: "Edit Company Product",
    productLabel:
      "e.g product name. Press enter key to add company product name",
    culture: "Company Culture",
    editCulture: "Edit Company Culture",
    philosophy: "Company Philosophy",
    editPhilosophy: "Edit Company Philosophy",
    responsibility: "Field of activity",
    editResponsibility: "Edit Field of activity",
    lookingFor: "Looking for",
    editLooking: "Edit Company looking for",
    companyBranches: "Company Branches",
    editCompanyBranches: "Edit Company Branches",
    addAnotherBranch: "Add another branch",
    lookingForStaff: "Looking for staff in the following fields",
    editLookingForStaff: "Edit Company looking for staff",
    lookingForWho: "Looking for who would be willing to work abroad",
    editLookingForWho: "Edit Work Abroad",
    noJob: "No Job Posted yet!",
    noField: "No field found"
  },
  companyJobPost: {
    jobTitle: "Job Title",
    location: "Location",
    yourLocation: "Your location branches",
    yourLocationNoBranch: "Your location branches (No branch added yet)",
    description: "Description",
    jobDesc: "Job Description",
    jobType: "Job Type",
    field: "Field",
    selectField: "Select your industry field",
    skills: "Skills",
    experience: "Experience",
    selectEx: "Select the experience level",
    languages: "Languages",
    payRate: "Pay Rate",
    selectPayRate: "Select pay rate of the job"
  }
};
